//
import axios from "axios";
import { insideHubBoundary } from "./availablityChecks";

export const handleDefaultLocationFetch = async (
  setLocationData,
  locationData
) => {
  try {
    const LC_TEMP_CART = JSON.parse(localStorage?.getItem("LC_TEMP_CART"));

    // Retrieve location from localStorage
    const localstorageLocation = JSON.parse(localStorage.getItem("U_LOCATION"));

    // Set selectedLocation based on localStorage or IP geolocation
    let selectedLocation;

    if (locationData.selectedLocation?.lat) {
      selectedLocation = locationData.selectedLocation;
    } else if (LC_TEMP_CART?.bookingAddress?.locationv2?.lat) {
      selectedLocation = LC_TEMP_CART?.bookingAddress?.locationv2;
    } else if (localstorageLocation?.lat) {
      selectedLocation = localstorageLocation;
    }

    // If selectedLocation doesn't have lat, fetch from IP geolocation
    if (!selectedLocation?.lat) {
      const ipapiRes = await axios.get("https://ipapi.co/json/");
      if (ipapiRes?.data) {
        selectedLocation =
          ipapiRes.data.region.toLowerCase().trim() === "west bengal"
            ? defaultSiliguriLocationData
            : defaultMumbaiLocationData;
      } else {
        selectedLocation = defaultMumbaiLocationData;
      }
    }

    // Check if the location falls within a specific hub's boundary
    let isPointInPolygon = await insideHubBoundary([
      selectedLocation.lng,
      selectedLocation.lat,
    ]);

    // If not, set default location for Mumbai and check again
    if (!isPointInPolygon.isInside) {
      selectedLocation = defaultMumbaiLocationData;
      isPointInPolygon = await insideHubBoundary([
        selectedLocation.lng,
        selectedLocation.lat,
      ]);
    }

    // Update localStorage and state with the selected location
    localStorage.setItem("U_LOCATION", JSON.stringify(selectedLocation));

    setLocationData((prevData) => ({
      ...prevData,
      selectedLocation: {
        ...selectedLocation,
        hubId: isPointInPolygon.foundInWhichHub,
      },
    }));

    return isPointInPolygon.foundInWhichHub;
  } catch (error) {
    console.error(error);
  }
};

// Default location data
const defaultSiliguriLocationData = {
  lat: 26.716976545721117,
  lng: 88.42376316458154,
  addressLine1: "Sevoke Road",
  addressLine2:
    "12, Sevoke Rd, Ward 10, Janta Nagar, Siliguri, West Bengal 734001, India",
  city: "Siliguri",
  state: "West Bengal",
  pincode: "734001",
};

const defaultMumbaiLocationData = {
  lat: 19.02852317173213,
  lng: 73.05990261302381,
  addressLine1: "Kharghar Skywalk",
  addressLine2:
    "23H5+FX3, Kharghar Skywalk, Sector 3, Belpada, Kharghar, Navi Mumbai, Maharashtra 410210, India",
  city: "Navi Mumbai",
  state: "Maharashtra",
  pincode: "410210",
};
