import React, { createContext, useState } from "react";

const CompRenderingContext = createContext();

export const CompRenderingProvider = ({ children }) => {
  const [isAddonTextInputModal, setIsAddonTextInputModal] = useState({
    isRender: false,
    text: "",
    addTextAddon: null,
    addon: null,
  });

  const [locationData, setLocationData] = useState({
    isLocationAddModalRender: false,
    selectedLocation: {
      hubId: "", // this stores the hub id (in which the user exists) on the initial load of site.
      lat: "",
      lng: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pincode: "",
    },
    isAddressSaveModal: false,
    addAndModiAddress: {
      isRender: false,
      options: {
        customZIndex: 15,
        isEdit: false,
        editingAddressInfo: null,
      },
    },
    toast1: {
      isRender: false,
      text: "",
      options: {
        customZIndex: 99,
      },
    },
  });

  const [universalModalsRenderInfo, setUniversalModalsRenderInfo] = useState({
    selectLocationModal: {
      isRender: false,
      options: {},
    },
    warningModel: {
      msg: "",
      leftBtnText: "",
      rightBtnText: "",
      isRender: false,
      funOnLeftBtn: () => {},
      funOnRightBtn: () => {},
    },
  });

  const [isLoginModal, setIsLoginModal] = useState(false); // side bar login modal
  const [loginModal, setLoginModal] = useState(false); // in between login modal

  const [calendarValue, setCalendarValue] = useState(null);

  const [isAddressSelector, setIsAddressSelector] = useState(false);
  const [header, setHeader] = useState({
    title: "ebo",
    isRender: true,
  });
  const [isCancel, setIsCancel] = useState(false);

  const [isPopup, setIsPopup] = useState(false);
  const [isRating, setIsRating] = useState({
    orderId: "",
    isRender: false,
    afterSuccessFun: () => {},
  });
  const [warningModal, setWarningModal] = useState({
    text: "Do you realy want to remove ?",
    isRender: false,
    funToBeExecuted: null,
    funToBeExecutedOnNo: null,
    textInsteadOfYes: "",
    textInsteadOfNo: "",
  });

  const [toastMsg, setToastMsg] = useState({
    msg: "",
    isRender: false,
  });

  const [successMsg, setSuccessMsg] = useState({
    msg: "OTP Sent",
    isRender: false,
  });

  //for coupon applied pop-up component

  const [couponModal, setCouponModal] = useState({
    img: "/img/couponSuccessLogo.svg",
    couponName: "",
    savAmount: "",
    isRender: false,
  });

  const [showBill, setShowBill] = useState({
    isRender: false,
    discountPrice: 0,
  });
  const [navAddressPop, setNavAddressPop] = useState(false);

  // ------------ opening and closing of addon detail cards --------------------
  const [isAddonDetailCard, setIsAddonDetailCard] = useState({
    addon: null,
    isRender: false,
  });

  //--------- referral code applied successful modal-------
  const [referralAppliedModal, setReferralAppliedModal] = useState(false);

  // state for popup model diplayed when i button is clicked on wallet page
  const [boxInfo, setBoxInfo] = useState({
    isRender: false,
    name: "",
    isClicked: null,
  });

  // pop up modal for diplay input box for instrcution on cart page
  const [instructionInputBox, setInstructionInputBox] = useState({
    isRender: false,
    order: null,
  });

  const [confrimationDoneUiRender, setConfirmationDoneUiRender] = useState({
    isRender: false,
    type: "",
  });

  window.toast = (msg, type) => {
    setLocationData((p) => ({
      ...p,
      toast1: {
        isRender: true,
        text: msg,
        options: {
          type: type || "",
        },
      },
    }));
  };

  window.showMsg = (message) => {
    setUniversalModalsRenderInfo((p) => ({
      ...p,
      warningModel: {
        msg: message,
        rightBtnText: "Ok",
        isRender: true,
      },
    }));
  };

  window.warningModal = ({ msg, rb_text, lb_text, rb_fn, lb_fn }) => {
    setUniversalModalsRenderInfo((p) => ({
      ...p,
      warningModel: {
        msg,
        leftBtnText: lb_text,
        rightBtnText: rb_text,
        funOnLeftBtn: lb_fn,
        funOnRightBtn: rb_fn,
        isRender: true,
      },
    }));
  };

  return (
    <CompRenderingContext.Provider
      value={{
        universalModalsRenderInfo,
        setUniversalModalsRenderInfo,
        isLoginModal,
        setIsLoginModal,
        isAddressSelector,
        setIsAddressSelector,
        calendarValue,
        setCalendarValue,
        isCancel,
        setIsCancel,
        isPopup,
        setIsPopup,
        isRating,
        setIsRating,
        warningModal,
        setWarningModal,
        header,
        setHeader,
        showBill,
        setShowBill,
        navAddressPop,
        setNavAddressPop,
        couponModal,
        setCouponModal,
        toastMsg,
        setToastMsg,
        successMsg,
        setSuccessMsg,
        loginModal,
        setLoginModal,
        isAddonDetailCard,
        setIsAddonDetailCard,
        referralAppliedModal,
        setReferralAppliedModal,
        boxInfo,
        setBoxInfo,
        instructionInputBox,
        setInstructionInputBox,
        confrimationDoneUiRender,
        setConfirmationDoneUiRender,
        locationData,
        setLocationData,
        isAddonTextInputModal,
        setIsAddonTextInputModal,
      }}
    >
      {children}
    </CompRenderingContext.Provider>
  );
};

export default CompRenderingContext;
