import { memo } from "react";
import { RxCross2 } from "react-icons/rx";
const Header = ({ modalClose }) => {
  const LoginPageHead = () => {
    return (
      <div className="flex flex-col items-center text-white ">
        <p
          style={{
            fontFamily: "'Josefin Sans', sans-serif",
          }}
          className=" text-8xl font-bold "
        >
          ebo
        </p>
        <p
          style={{ fontFamily: " 'Carter One', system-ui" }}
          className="text-2xl font-thin"
        >
          celebration ho toh
          <span
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className="font-[700] text-[1.75rem]"
          >
            {" "}
            ebo
          </span>
          !
        </p>
      </div>
    );
  };
  return (
    <div className=" flex justify-center items-center blue-gradient  h-[35vh]  sticky top-0 py-4 rounded-b-[30px] w-[100%]">
      <LoginPageHead />
      <div className=" absolute top-[32px] right-[28px] cursor-pointer">
        <RxCross2
          onClick={() => {
            modalClose();
          }}
          className="text-white text-[1.7rem]"
        />
      </div>
    </div>
  );
};

export default memo(Header);
