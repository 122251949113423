import { useContext, memo } from "react";
import AuthContext from "../../context/AuthContext";
import LoginForm from "../../components/authSidebar/LoginForm";
import OTPform from "../../components/authSidebar/OTPform";
import RegisterForm from "../../components/authSidebar/RegisterForm";
import Header from "./Header";

const MainForm = ({ modalClose }) => {
  const {
    authPage: { login, otp },
  } = useContext(AuthContext);

  return (
    <div className="">
      {(login || otp) && <Header modalClose={modalClose} />}
      <div
        className={`px-6 w-full ${
          login || otp ? "top-[35vh]" : " top-[1px]"
        }  bottom-0  fixed overflow-scroll  scroll-bar-remove`}
      >
        {login ? (
          <LoginForm modalClose={modalClose} />
        ) : otp ? (
          <OTPform modalClose={modalClose} />
        ) : (
          <RegisterForm modalClose={modalClose} />
        )}
      </div>
    </div>
  );
};

export default memo(MainForm);
