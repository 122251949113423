import React from "react";
import { useNavigate } from "react-router-dom";

export default function LoginBanner() {
  const nav = useNavigate();
  return (
    <div
      className="bg-[#2136D4] -my-2 md:-my-1 h-[5rem] md:h-[8rem] flex items-center 
    justify-between rounded-[10px]  pt-4 md:pt-6 pb-4 w-full border-2 px-3 md:px-[2rem] md:mx-8 mx-2 md:py-4"
    >
      <div className="font-bold md:mt-2  ">
        <div
          className="flex flex-col md:flex-row
         text-white  gap-2  "
        >
          <p className="text-[17px] md:text-[28px]">
            Login now to claim free gifts!
          </p>
          <p className="text-white text-xs md:hidden ">
            Get additional benefits on signing up now.{" "}
          </p>
        </div>
        <p className="text-white mt-4 text-[18px] hidden md:block ">
          Get additional benefits on signing up now.{" "}
        </p>
      </div>
      <button
        className="bg-white text-[#2136D4] text-[18px] md:text-[36px] px-3 py-1 md:px-3
        text-center rounded-[5px] md:rounded-[10px] font-bold"
        onClick={() => {
          nav("/login");
        }}
      >
        Log in
      </button>
    </div>
  );
}
