import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SubCategories({
  subCategoriesList,
  decorCategoriesList,
  categoryCode,
}) {
  // subCategoriesList=[1,2,3,4,5,6,7]
  const [states, setStates] = useState({
    selected: "", //will be used based on the selected category or subcategory
  });

  const nav = useNavigate();

  const hanldeSubCatClick = async () => {};
  const hanldeCatClick = async () => {};

  return (
    <div className="flex w-full gap-8 items-start max-w-[100vw] px-2 whitespace-nowrap overflow-x-scroll scroll-bar-remove  ">
      {subCategoriesList?.length > 0 &&
        subCategoriesList.map((curr, idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                nav(
                  `/products/category/` +
                    categoryCode +
                    `?filter={"subCategories":"${curr.name}"}`
                );
              }}
              className={`min-w-[3.5rem] flex flex-col gap-1 justify-center items-center`}
            >
              <img
                src={curr.icon}
                alt="subCat"
                className=" rounded-full w-[3.5rem] h-[3.5rem] object-cover"
              />
              <p className="capitalize text-[.875rem] text-center font-[500] ">
                {curr.name}
              </p>
              {/* <BottomLine /> */}
            </div>
          );
        })}

      {/* {decorCategoriesList?.length > 0 &&
        decorCategoriesList
          .slice(0, 9 - subCategoriesList?.length || 0)
          .map((curr, idx) => {
            if (parseInt(curr.code) === parseInt(categoryCode)) {
              return <></>;
            }
            return (
              <div
                key={idx}
                onClick={() => {
                  nav(`/products/category/` + curr.code);
                }}
                className={`min-w-[3.5rem] flex flex-col gap-1 justify-center items-center`}
              >
                <img
                  src={curr.icon}
                  alt="decorCat"
                  className=" rounded-full w-[3.5rem] h-[3.5rem] object-cover"
                />
                <p className="capitalize text-[.875rem] text-center font-[500]">
                  {curr.categoryName}
                </p>
              </div>
            );
          })} */}
    </div>
  );

  function BottomLine() {
    return <div className="blue-gradient h-1 w-full rounded-t-[4px]"></div>;
  }
}
