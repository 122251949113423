import ClipLoader from "react-spinners/ClipLoader";

const Spinner = ({ color, top, left, size }) => {
  return (
    <div
      className={`absolute ${top ? top : "top-[49%]"} ${
        left ? left : "left-[48%]"
      } rounded-md z-20`}
    >
      <ClipLoader color={color ? color : "#2136d4"} size={size ? size : 30} />
    </div>
  );
};

export default Spinner;
