import { useContext, memo, useEffect, useState } from "react";
import Error from "../../assets/Error";
import AuthContext from "../../context/AuthContext";
import Button from "./Button";
import PhoneInput from "./PhoneInput";

const LoginForm = () => {
  const { number, setNumber, error, setError, otpGeneration, serverRes } =
    useContext(AuthContext);

  const [states, setStates] = useState({
    isValidNumber: false,
  });

  useEffect(() => {
    const isValidNumber = /^\d{10}$/.test(number);
    setStates((prev) => ({
      ...prev,
      isValidNumber: isValidNumber,
    }));
  }, [number]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the phone number has exactly 10 digits
    const isValidNumber = /^\d{10}$/.test(number);
    setStates((prev) => ({
      ...prev,
      isValidNumber: isValidNumber,
    }));

    if (!isValidNumber) {
      setError("Number must be 10 digits");
      return;
    }
    // Proceed with OTP generation logic
    otpGeneration();
  };

  // console.log(states.isValidNumber)
  return (
    <div className="font-medium ">
      <div className="mt-4 font-medium">
        <p>Login or Signup</p>
        <hr className="mt-1 border-t-2 border-solid border-[#2136D4] w-[70px] " />
      </div>
      <form onSubmit={handleSubmit} className="w-[100%]">
        <div className={" pt-1 pb-2 w-[100%]"}>
          <PhoneInput number={number} setNumber={setNumber} />
        </div>
        <Error setError={setError} error={error} />
        <Button
          text={"continue"}
          type={"submit"}
          auto
          isLoading={serverRes.isLoading}
          disabled={!states.isValidNumber}
        />

        {/* <div className="w-full flex gap-2 my-4 text-[grey] text-[1rem] items-center justify-between">
        <span className="w-full border-t"></span>
        <span>or</span>
        <span className="w-full border-t"></span>
      </div>
        otp less button
        <button
        id="otpless"
        custom={"true"}
        className="border border-[#d9d9d9] w-full gap-1 font-[500] flex items-center justify-center rounded-md text-black py-3"
      >
        <RiWhatsappFill className="text-[#25D366] text text-[1.25rem] " />{" "}
        Continue with Whatsapp
      </button>{" "} */}
      </form>

      <p className="text-xs py-4 text-[#817C7C] flex flex-wrap items-center gap-1">
        By clicking on Login, I accept the{" "}
        <div
          onClick={() => {
            window.openUrl(`https://www.ebonow.com/terms`);
          }}
          className="text-sm font-medium md:font-semibold text-[#2136d4]"
        >
          Terms & Conditions
        </div>
        &
        <div
          onClick={() => {
            window.openUrl(`https://www.ebonow.com/privacy`);
          }}
          className="text-sm font-medium md:font-semibold text-[#2136d4]"
        >
          Privacy Policy
        </div>
      </p>

      {/* <div className="flex items-center my-4">
        <hr className="w-1/2 flex-grow border-t border-gray-200" />
        <span className="px-3 text-gray-500">OR</span>
        <hr className="w-1/2 flex-grow border-t border-gray-200" />
      </div> */}
      {/* <div className="flex gap-2 items-center justify-center w-full border rounded-[10px] py-3 ">
        <RiWhatsappFill className="text-[#25D366] text text-2xl " />
        <p className="text-[#5B5B5B] ">One tap login with Whatsapp</p>
      </div> */}
    </div>
  );
};

export default memo(LoginForm);
