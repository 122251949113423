import { useContext, useEffect, useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BiSolidUser, BiUser } from "react-icons/bi";
import {
  PiHouseSimple,
  PiHouseSimpleFill,
  PiNotepad,
  PiNotepadFill,
} from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import useKeyboarListener from "../../hooks/useKeyboardListener";
import GLobalStoreContext from "../../context/GlobalStoreContext";

export default function BottomNav() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isKeyboardOpen } = useKeyboarListener();

  const { sendMessageToNative } = useContext(GLobalStoreContext);
  const { user } = useContext(AuthContext);

  const [states, setStates] = useState({
    isRenderBottomNav: true,
    pathArray: [
      "/",
      "/profile/wishlist",
      "/profile/wishlist/",
      "/profile/mybookings",
      "/profile/mybookings/",
      "/profile/myaccount",
      "/profile/myaccount/",
    ],
  });

  useEffect(() => {
    const isPathInArray = states.pathArray.includes(pathname);
    setStates((prev) => ({ ...prev, isRenderBottomNav: isPathInArray }));
  }, [pathname, states.pathArray]);

  const handleClick = (path) => {
    sendMessageToNative({
      action: "",
      trigger: "vibration",
      impactLevel: "light",
    });
    navigate(path);
  };

  // if (states.isRenderBottomNav === false || isKeyboardOpen || !user) return <></>;
  if (
    states.isRenderBottomNav === false ||
    isKeyboardOpen ||
    pathname === "/login"
  )
    return <></>;

  const iconContainerStyle = "flex flex-col items-center w-[18%]  ";

  return (
    <div className="fixed bottom-0 left-0 right-0 z-[15] ">
      <div
        style={{
          boxShadow: "0 0 10px #d9d9d9",
        }}
        className="flex justify-between items-start text-base  bg-white border-t border-[#D9D9D9] rounded-t-xl px-4 h-fit  "
      >
        <div
          style={{
            fontFamily: "'Josefin Sans', sans-serif",
          }}
          className={`${iconContainerStyle} ${
            pathname === "/"
              ? "text-[#2136D4] font-[900] relative"
              : "font-[700] text-[#5f5f5f]"
          } relative text-[1rem] py-2 pb-[6px] `}
          onClick={() => handleClick("/")}
        >
          {pathname === "/" && <TopLine />}

          <div className=" text-[28px] -mb-[4px] relative ">
            {pathname === "/" ? <PiHouseSimpleFill /> : <PiHouseSimple />}
            <p
              className={`absolute top-[4px] text-[18px] right-[9px] font-bold  ${
                pathname === "/" && "text-white"
              } -rotate-[20deg] `}
            >
              e
            </p>
          </div>

          <p className="relative bottom-[1px]">ebo</p>
        </div>
        {links.map((_, i) => {
          return (
            <EachLinks
              key={i}
              _={_}
              handleClick={handleClick}
              user={user}
              pathname={pathname}
            />
          );
        })}
      </div>
    </div>
  );
}
function TopLine() {
  return (
    <div className="bg-[#2136D4] h-1 w-full rounded-b-lg absolute bottom-0 -top-[1px] z-10" />
  );
}
const EachLinks = ({ _, handleClick, pathname, user }) => {
  const iconContainerStyle = "flex flex-col items-center w-[18%]  ";
  const { wishlistedArrayObj } = useContext(GLobalStoreContext);
  return (
    <div
      className={`${iconContainerStyle} ${
        pathname === _.path
          ? "text-[#2136D4] font-[600] relative"
          : "font-[500] text-[#5f5f5f]"
      } relative text-[.875rem] py-2 pb-[6px] `}
      onClick={() => handleClick(_.path)}
    >
      {pathname === _.path && <TopLine />}
      <div className=" text-[28px] -mb-[4px] ">
        {pathname === _.path ? _.activeIcon : _.inActiveIcon}
      </div>
      <p className={`${pathname === _.path ? " " : " "}`}> {_.label}</p>
      {_.label === "Wishlist" && wishlistedArrayObj?.length > 0 && (
        <div className=" w-3 h-3 flex items-center justify-center rounded-full blue-gradient absolute top-[6px] right-4 text-[10px]">
          {wishlistedArrayObj?.length}
        </div>
      )}
    </div>
  );
};

const links = [
  {
    label: "Wishlist",
    inActiveIcon: <AiOutlineHeart />,
    activeIcon: <AiFillHeart />,
    path: "/profile/wishlist",
  },
  {
    label: "Booking",
    inActiveIcon: <PiNotepad />,
    activeIcon: <PiNotepadFill />,
    path: "/profile/mybookings",
  },
  {
    label: "Profile",
    inActiveIcon: <BiUser />,
    activeIcon: <BiSolidUser />,
    path: "/profile/myaccount",
  },
];
