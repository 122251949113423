import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  memo,
  useCallback,
} from "react";
// import bcrypt from "bcryptjs";
import axios from "axios";
// import moment from "moment";
import CompRenderingContext from "./CompRenderingContext";
import {
  checkOTPAndVerifyApi,
  generate_otp,
  get_user,
  grant_access_for_admin,
  register_user,
} from "../api/userApi";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import GLobalStoreContext from "./GlobalStoreContext";

const AuthContext = createContext();

export const AuthProvider = memo(({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  // ------------- ** LOCAL STORAGE ** ----------------
  const user = JSON.parse(localStorage?.getItem("user"));

  // ------------ ** CONTEXTS ** ------------------------------

  const { setIsLoginModal, setSuccessMsg } = useContext(CompRenderingContext);
  const { handleContentLoadOnUserChange } = useContext(GLobalStoreContext);
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // ---------------- ** STATES ** ------------------------------

  // otp state
  const [serverRes, setServerRes] = useState({
    isLoading: false,
    data: null,
  });

  //initial user state
  const [initialState, setInitialState] = useState({
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    type: "",
  });

  //updating user details state
  const [isSaving, setIsSaving] = useState(false);

  //phone input state
  const [number, setNumber] = useState("");

  //state errors
  const [error, setError] = useState("");

  //form rendering on condition
  const [authPage, setAuthPage] = useState({
    login: true,
    otp: false,
    register: false,
  });

  // ---------- ** USE EFFECTS ** ----------------

  useEffect(() => {
    // wishlist first time load
    // handleContentLoadOnUserChange function contains wishlist first time load logic
    handleContentLoadOnUserChange();

    // eslint-disable-next-line
  }, [initialState?.user]);

  useEffect(() => {
    const accessToken = searchParams.get("accessToken");
    if (accessToken) {
      window && handleAdminAccess(accessToken);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  // ----------------- ** FUNCTIONS ** ----------------

  const handleAdminAccess = async (accessToken) => {
    localStorage.clear();

    const res = await grant_access_for_admin({
      url: REACT_APP_BACKEND_URL,
      accessToken,
    });

    if (res.isSuccess) {
      setInitialState({
        ...initialState,
        user: res.user,
        isLoading: false,
      });
      localStorage.setItem("user", JSON.stringify(res.user));
    }
    handleRemoveQueryParams();
  };

  const handleRemoveQueryParams = () => {
    // Extract the pathname from the current location
    const { pathname } = location;

    // Replace the current URL without query parameters
    navigate(pathname);
  };

  //for closing sidebar
  const closeSideBar = useCallback(() => {
    setIsLoginModal(false);
    //eslint-disable-next-line
  }, []);

  /**
   * initialize the opt generation
   */
  const otpGeneration = async (type) => {
    try {
      setServerRes({
        ...serverRes,
        isLoading: true,
      });
      const res = await generate_otp({
        url: REACT_APP_BACKEND_URL,
        number,
        type,
      });
      if (res.isSuccess) {
        setServerRes({
          isLoading: false,
          data: res,
        });
        // will open the otp page
        setAuthPage({
          ...authPage,
          otp: true,
          login: false,
        });
        window.toast("OTP Sent to your phone number");
      } else {
        setServerRes({
          ...serverRes,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = useCallback(async () => {
    try {
      setInitialState({
        ...initialState,
        isLoading: true,
      });
      const res = await get_user({ url: REACT_APP_BACKEND_URL, number });
      if (res.isSuccess) {
        setInitialState({
          ...initialState,
          user: res.user,
          isLoading: false,
        });
        window && localStorage.setItem("user", JSON.stringify(res.user));
        // window.location.reload();
      } else {
        setInitialState({
          ...initialState,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      setInitialState({
        ...initialState,
        isLoading: false,
      });
    }
  }, [REACT_APP_BACKEND_URL, number, initialState]);

  //registering new user
  const registerUser = async (formData, isCloseSideBar) => {
    try {
      setInitialState((prev) => ({ ...prev, isLoading: true }));
      const res = await register_user({
        url: REACT_APP_BACKEND_URL,
        formdata: formData,
      });
      if (res.isSuccess) {
        window && localStorage.setItem("user", JSON.stringify(res.user));

        setSuccessMsg({
          msg: `Welcome, ${
            res.user.username && res.user.username !== "User"
              ? res.user.username
              : ""
          } to ebo`,
          isRender: true,
        });
        setInitialState((prev) => ({
          ...prev,
          isLoading: false,
          user: res.user,
        }));
        navigate(-1);
      } else {
        setInitialState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
      if (isCloseSideBar) {
        setAuthPage({ login: true, otp: false, register: false });
      }
    } catch (error) {
      console.log(error);
      setInitialState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const checkOTP = async (inputedOTP) => {
    const res = await checkOTPAndVerifyApi({
      otp: inputedOTP,
      hashOtp: serverRes.data.hash,
      phoneNumber: number,
    });
    if (res.isSuccess) {
      if (res.isRegistered) {
        localStorage.setItem("user", JSON.stringify(res.user));
        setInitialState({
          ...initialState,
          user: res.user,
          isLoading: false,
        });
        setAuthPage({
          login: true,
          otp: false,
          register: false,
        });
        navigate(-1);
        window.toast("Logged in successfully");
      } else {
        window.toast("OTP Verified successfully");

        setAuthPage({
          otp: false,
          login: false,
          register: true,
        });
      }
      return { matched: true };
    } else {
      return { matched: false };
    }
  };

  //edit user details
  const editUser = useCallback(
    async (userData) => {
      try {
        setIsSaving(true);
        const res = await axios.post(
          `${REACT_APP_BACKEND_URL}/api/users/edit-user`,
          userData,
          {
            headers: {
              Authorization: "Bearer " + user.token,
            },
          }
        );
        setIsSaving(false);
        if (res.status === 200) {
          setInitialState({
            ...initialState,
            isSuccess: true,
            user: res.data.user,
          });
          window && localStorage.setItem("user", JSON.stringify(res.data.user));
        } else {
          console.log("Error in updating user");
        }

        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
    [REACT_APP_BACKEND_URL, user, initialState]
  );

  //log out a user
  const logout = () => {
    window && localStorage.clear();
    setInitialState((prev) => ({ ...prev, user: null }));
    setAuthPage({ login: true, otp: false, register: false });
    window.location.href = "/";
  };

  const resendOTPFunc = async (type) => {
    await otpGeneration(type);
  };

  //input border css
  const inputBorder = "border-b-[1px] border-[rgba(0,0,0,0.3)] py-3 my-3";

  return (
    <AuthContext.Provider
      value={{
        user,
        inputBorder,
        authPage,
        setAuthPage,
        number,
        setNumber,
        initialState,
        setInitialState,
        closeSideBar,
        registerUser,
        logout,
        error,
        setError,
        resendOTPFunc,
        editUser,
        isSaving,
        otpGeneration,
        checkOTP,
        serverRes,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export default AuthContext;
