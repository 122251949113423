import React, { useContext, useEffect } from "react";
import GLobalStoreContext from "../context/GlobalStoreContext";

/*
use case: 
  const containerRef = useRef(null);
 
 const [refereshStates, setRefereshStates] = useState({});


  usePullToRefresh(
    containerRef,
    () => console.log("triggers referesh"),
    (params) => {
      setRefereshStates(params);
    }
  );

 <div ref={containerRef} className="home-cont relative  md:top-[6rem]">  // the container on which you want the refresh effect
        <RefreshLoader refereshStates={refereshStates} />
        // rest components
 </div>


*/

function usePullToRefresh(ref, onTrigger, refreshIndicator) {
  const MAX = 120;
  const k = 0.4;
  const TRIGGER_THRESHOLD = 150;
  const SHOW_INDICATOR_THRESHOLD = 30;
  function appr(x) {
    return MAX * (1 - Math.exp((-k * x) / MAX));
  }
  useEffect(() => {
    const handleTouchStart = (startEvent) => {
      const el = ref?.current;
      if (!el) return;

      // get the initial Y position
      const initialY = startEvent.touches[0].clientY;

      el.addEventListener("touchmove", handleTouchMove);
      el.addEventListener("touchend", handleTouchEnd);

      function handleTouchMove(moveEvent) {
        const el = ref?.current;
        if (!el) return;

        // get the current Y position
        const currentY = moveEvent.touches[0].clientY;

        // get the difference
        const dy = currentY - initialY;

        if (dy < 0) return;
        if (dy > TRIGGER_THRESHOLD) {
          refreshIndicator({
            dy,
            loaderRender: true,
            TRIGGER_THRESHOLD,
            SHOW_INDICATOR_THRESHOLD,
          });
        } else if (dy > SHOW_INDICATOR_THRESHOLD) {
          refreshIndicator({
            dy,
            loaderRender: true,
            TRIGGER_THRESHOLD,
            SHOW_INDICATOR_THRESHOLD,
          });
        } else {
          refreshIndicator({
            dy,
            loaderRender: false,
            TRIGGER_THRESHOLD,
            SHOW_INDICATOR_THRESHOLD,
          });
        }

        // now we are using the `appr` function
        el.style.transform = `translateY(${appr(dy)}px)`;
      }
      function handleTouchEnd(endEvent) {
        const el = ref?.current;
        if (!el) return;

        // return the element to its initial position
        el.style.transform = "translateY(0)";

        // add transition
        el.style.transition = "transform 0.2s";

        // run the callback
        const y = endEvent.changedTouches[0].clientY;
        const dy = y - initialY;
        const parts = Math.floor(
          (dy - SHOW_INDICATOR_THRESHOLD > 0
            ? dy - SHOW_INDICATOR_THRESHOLD
            : 0) /
            (TRIGGER_THRESHOLD / 12)
        );
        const linesToShow = Math.min(parts, 12);

        const containerYPosition = el.scrollTop;
        if (linesToShow >= 12 && containerYPosition <= 10) {
          // handleVibrate();
          onTrigger();
          refreshIndicator({
            dy,
            loaderRender: false,
            TRIGGER_THRESHOLD,
            SHOW_INDICATOR_THRESHOLD,
          });
        } else if (dy >= 10) {
          refreshIndicator({
            dy,
            loaderRender: false,
            TRIGGER_THRESHOLD,
            SHOW_INDICATOR_THRESHOLD,
          });
        }

        // listen for transition end event
        el.addEventListener("transitionend", onTransitionEnd);

        // cleanup
        el.removeEventListener("touchmove", handleTouchMove);
        el.removeEventListener("touchend", handleTouchEnd);
      }

      function onTransitionEnd() {
        const el = ref?.current;
        if (!el) return;

        // remove transition
        el.style.transition = "";

        // cleanup
        el.removeEventListener("transitionend", onTransitionEnd);
      }
    };

    const el = ref?.current;
    if (el) {
      el.addEventListener("touchstart", handleTouchStart);
    }

    return () => {
      if (el) {
        el.removeEventListener("touchstart", handleTouchStart);
      }
    };

    // eslint-disable-next-line
  }, [ref, onTrigger]);
}
let isVibrateFlag = true;
export function RefreshLoader({ refereshStates }) {
  const totalParts = 12;
  const TRIGGER_THRESHOLD = refereshStates.TRIGGER_THRESHOLD / totalParts;
  const { handleVibrate } = useContext(GLobalStoreContext);
  const parts = Math.floor(
    (refereshStates.dy - refereshStates.SHOW_INDICATOR_THRESHOLD > 0
      ? refereshStates.dy - refereshStates.SHOW_INDICATOR_THRESHOLD
      : 0) / TRIGGER_THRESHOLD
  );
  const linesToShow = Math.min(parts, 13);
  useEffect(() => {
    if (linesToShow === 12 && isVibrateFlag) {
      handleVibrate();
      isVibrateFlag = false;
    }
    if (linesToShow < 12 && !isVibrateFlag) {
      isVibrateFlag = true;
    }
    // eslint-disable-next-line
  }, [linesToShow]);
  const lineStyle = {
    margin: "24px",
    width: "2.5px",
    height: "9px",
    background: "#d9d9d9",
    position: "absolute",
    transformOrigin: "center bottom",
  };

  const keyframeStyle = `
          @keyframes opacityChange {
            0% {
              opacity: 0.2;
            }    
            100%{
              opacity:1;
            }
          }
        `;
  if (!refereshStates.loaderRender) return <></>;
  return (
    <div className=" absolute z-[15] bg-[#2136d4] top-[1rem] right-0 left-0 ">
      <div className="relative w-full h-full flex justify-center items-center">
        <style>{keyframeStyle}</style>
        {Array.from({ length: linesToShow }).map((_, index) => (
          <div
            key={index}
            style={{
              ...lineStyle,
              transform: `rotate(calc(${index} * (360deg / 12)))  translateY(-68%) translateX(-5%)`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default usePullToRefresh;
