import React from "react";
import { useNavigate } from "react-router-dom";

export default function OfferBanner({ idx, productListingpageBanners }) {
  const nav = useNavigate();
  let banner;
  // if (idx === 8) {
  //   banner = productListingpageBanners?.inBetweenProductBanner1;
  // } else {
  //   banner = productListingpageBanners?.inBetweenProductBanner2;
  // }
  banner = productListingpageBanners?.inBetweenProductBanner2;

  if (banner && banner !== "") {
    return (
      <div className="w-full -my-2 md:-my-1 px-2 md:px-[1rem]">
        <div
          onClick={() => {
            const url = banner?.redirectUrl;

            if (url && url !== "") {
              if (url.includes("http")) {
                // External URL, navigate to a different domain
                window.location.href = url;
              } else {
                // Internal URL, change the pathname within your application
                nav(url);
              }
            }
          }}
          className="flex items-center justify-center w-[100%]"
        >
          <img
            src={banner?.bannerImgUrl}
            alt="ebo now offer banner"
            loading="lazy"
            title="ebo now offer banner"
            style={{ aspectRatio: 4 / 1 }}
            className="w-[100%] border border-[#ebebeb] rounded-md"
          />
        </div>
      </div>
    );
  }
  return <></>;
}
