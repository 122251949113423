import React, { useEffect } from "react";
import { motion } from "framer-motion";

/*
 steps to use this component
 step 1: create state

   const [modalsRenderInfo, setModalsRenderInfo] = useState({
    toast1: {
      isRender: false,
      text: '',
      options: {
        customZIndex: 15
      },
    },
  });

 step 2:: import the component conditionally

    <AnimatePresence>
        {modalsRenderInfo.toast1.isRender && (
          <Toast1
            modalsRenderInfo={modalsRenderInfo}
            setModalsRenderInfo={setModalsRenderInfo}
          />
        )}
      </AnimatePresence>

  step 3: change the state conditionally to render the modal
*/

export default function Toast1({ modalsRenderInfo, setModalsRenderInfo }) {
  useEffect(() => {
    setTimeout(() => {
      handleClose();
    }, 2000);
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setModalsRenderInfo((p) => ({
      ...p,
      toast1: {
        isRender: false,
        text: "",
        options: {},
      },
    }));
  };

  return (
    <div
      style={{
        zIndex: modalsRenderInfo.toast1.options.customZIndex || 15,
      }}
      className="fixed  top-[3rem] right-0 left-0 flex text-[black] justify-center "
    >
      <motion.div
        onClick={handleClose}
        initial={{ translateY: 20, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        exit={{ translateY: -50, opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
        className="text-[.825rem] px-4 py-1 text-center border border-[#a3c1fe] rounded-md bg-[#F0F5FE]"
      >
        {modalsRenderInfo.toast1.text}
      </motion.div>
    </div>
  );
}
