import { useContext, useEffect, useState, memo, useRef } from "react";
import AuthContext from "../../context/AuthContext";
import Button from "./Button";
import Error from "../../assets/Error";
import { MdOutlineTextsms } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import OTPDigitsInput from "../../assets/inputUIs/OTPDigitsInput";

const OTPform = () => {
  const { setAuthPage, number, setInitialState, resendOTPFunc, checkOTP } =
    useContext(AuthContext);
  const otpInputField = useRef(null);

  const [isInputFocus, setIsInputFocus] = useState(false);
  const [inputedOTP, setInputedOTP] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isVerificationStarted, setIsVerificationStarted] = useState(false);

  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer((prev) => prev - 1), 1000);
    }
  }, [timer]);

  useEffect(() => {
    if (inputedOTP.length === 6) {
      // All OTP digits are filled
      setIsVerificationStarted(true);
      verifyOTP();
    }

    // eslint-disable-next-line
  }, [inputedOTP]);

  const verifyOTP = async (e) => {
    e && e.preventDefault();
    setIsLoading(true);
    if (inputedOTP.length < 6) {
      setError("OTP should have minimul length of six");
      setIsLoading(false);
      return;
    }

    try {
      const res = await checkOTP(inputedOTP);
      if (!res.matched) {
        setError("wrong OTP");
      }
      setIsVerificationStarted(false);
      setIsLoading(false);
    } catch (error) {
      setInitialState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const onEdit = () => {
    setAuthPage((prev) => ({ ...prev, login: true, otp: false }));
  };

  const resendOTP = (type) => {
    if (timer === 0) {
      setInputedOTP("");
      resendOTPFunc(type);
      setTimer(30);
    }
  };

  return (
    <form onSubmit={verifyOTP} className="">
      <div className="flex relative text-[.825rem] mt-8 text-[#666666] gap-[1rem] font-medium">
        <p className="flex gap-1">
          Enter OTP sent to <span>+91 {number}</span>{" "}
        </p>{" "}
        <span
          onClick={onEdit}
          className="font-[500] cursor-pointer text-[#0446D4] "
        >
          Edit
        </span>
      </div>
      <hr className="mt-1 border-t-2 border-solid border-[#2136D4] w-[70px] " />

      <div className="relative mb-2 mt-4">
        <OTPDigitsInput
          {...{
            inputedOTP,
            setInputedOTP,
            isInputFocus,
            setIsInputFocus,
            otpInputField,
          }}
        />
        {timer > 0 ? (
          <div className="flex   gap-1 justify-center items-center  text-xs font-medium md:font-semibold">
            <span
              className={`my-3 ${
                timer > 0
                  ? "pointer-events-none text-[#979797]"
                  : "cursor-pointer  text-[#2136D4] "
              }`}
            >
              Resend OTP in
            </span>
            {timer > 0 && (
              <span className="text-[#2136d4]">
                00:{timer < 10 ? "0" + timer : timer}
              </span>
            )}
          </div>
        ) : (
          <>
            <div className="text-[gray] text-[.875rem] mt-3 text-center ">
              Didn't receive the OTP? Verify via
            </div>
            <div className="w-full mb-4 mt-2 flex justify-center gap-[2rem] text-[.8125rem] items-center">
              <div
                onClick={() => {
                  window.toast("OTP sent to your number");
                  resendOTP("sms");
                }}
                className="rounded-lg border border-gray-200 flex items-center gap-1  px-4 py-2 "
              >
                <MdOutlineTextsms className="text-[.875rem]" /> Resend SMS
              </div>
              <div
                onClick={() => {
                  window.toast("OTP sent to your whatsapp");
                  resendOTP("whatsapp");
                }}
                className="rounded-lg border  border-gray-200 flex items-center gap-1  px-4 py-2 "
              >
                <IoLogoWhatsapp className="text-green-500 text-[.875rem]" />
                Whatsapp
              </div>
            </div>
          </>
        )}
      </div>
      <Error setError={setError} error={error} />
      <Button
        isLoading={isLoading}
        type={"submit"}
        text={"Verify"}
        disabled={!isVerificationStarted}
      />
    </form>
  );
};

export default memo(OTPform);
