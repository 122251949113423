import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CartContext from "./CartContext";
import { get_offers_by_query_object } from "../api/offerApi";
import GLobalStoreContext from "./GlobalStoreContext";
import CompRenderingContext from "./CompRenderingContext";
import { offer_min_value_invalidation_check } from "../functions/tempCartFunc";

const OfferContext = createContext();

export const OfferProvider = ({ children }) => {
  // -------------- ** context ** -------------
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { tempCart, temp_cart_add_offer } = useContext(CartContext);
  const { handleVibrate } = useContext(GLobalStoreContext);
  const { locationData } = useContext(CompRenderingContext);

  // -------------- ** states ** -------------
  const [offerState, setOfferState] = useState({
    offers: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    type: "",
  });

  const [availableOffers, setAvailableOffers] = useState([]);

  const [missingOutOffers, setMissingOutOffers] = useState([]);

  // ------------------ ** use effects ** -------------------
  useEffect(() => {
    tempCart && offerFilteration();
    // eslint-disable-next-line
  }, [offerState, tempCart]);

  useEffect(() => {
    // getting offer details ---
    (offerState.offers.length === 0 || !offerState.offers) && getOffers();
    // eslint-disable-next-line
  }, []);

  const onRemoveCoupon = async () => {
    handleVibrate();
    await temp_cart_add_offer(null);
  };

  const offerFilteration = useCallback(() => {
    let availableOffersList = [];
    let notAvailableOffersList = [];

    for (const offer of offerState.offers) {
      const invalidation = offer_min_value_invalidation_check(
        offer,
        tempCart,
        locationData?.selectedLocation?.hubId
      );
      if (invalidation.invalid) {
        notAvailableOffersList.push(offer);
      } else {
        availableOffersList.push(offer);
      }
    }

    setAvailableOffers(availableOffersList);
    setMissingOutOffers(notAvailableOffersList);

    // eslint-disable-next-line
  }, [offerState, tempCart]);

  const getOffers = async () => {
    setOfferState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const res = await get_offers_by_query_object({
        BaseUrl: REACT_APP_BACKEND_URL,
        queryObject: {},
        skip: 0,
        limit: 100,
        sortingObj: {},
      });
      if (res.isSuccess) {
        setOfferState({
          ...offerState,
          isLoading: false,
          offers: res.offers,
        });
      } else {
        setOfferState({
          ...offerState,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      setOfferState({
        ...offerState,
        isLoading: false,
      });
    }
    // eslint-disable-next-line
  };

  const offerValidationCheck = async (couponCode) => {
    if (couponCode.toUpperCase() === "")
      return {
        message: "Please enter a promo code",
        isSuccess: false,
        isInvalid: true,
      };

    let offerDetails = null;
    offerState.offers.forEach((currOffer) => {
      if (couponCode.toUpperCase().trim() === currOffer.offerCode)
        offerDetails = currOffer;
    });

    if (!offerDetails) {
      return {
        message: `Promo code ${couponCode.toUpperCase()} is invalid. Please try another code `,
        isSuccess: false,
        isInvalid: true,
      };
    }

    const validation = offer_min_value_invalidation_check(
      offerDetails,
      tempCart,
      locationData?.selectedLocation?.hubId
    );

    if (validation.invalid) {
      return {
        message: ` Add worth ₹${validation.moneyNeededToGetOffer} more to get this offer`,
        isSuccess: false,
        isInvalid: true,
      };
    } else {
      await temp_cart_add_offer(offerDetails);
      return {
        message: "Promo code applied successfully",
        isSuccess: true,
        isInvalid: false,
        offerDetails: offerDetails,
      };
    }
  };

  return (
    <OfferContext.Provider
      value={{
        offerState,
        getOffers,
        availableOffers,
        setAvailableOffers,
        missingOutOffers,
        setMissingOutOffers,
        onRemoveCoupon,
        offerValidationCheck,
        offerFilteration,
      }}
    >
      {children}
    </OfferContext.Provider>
  );
};

export default OfferContext;
