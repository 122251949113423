import { Fragment, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import LoginBanner from "./LoginBanner";
import OfferBanner from "./OfferBanner";
import CustomizeDecorBanner from "../homeComponents/CustomizeDecorOfferBanner";

export default function CustomBanner({ idx, productListingpageBanners }) {
  const { user } = useContext(AuthContext);
  return (
    <Fragment>
      {user ? (
        <>
          {idx % 16 !== 0 ? (
            <div className="w-full mx-2">
              <CustomizeDecorBanner />
            </div>
          ) : (
            <OfferBanner
              productListingpageBanners={productListingpageBanners}
              idx={idx}
            />
          )}
        </>
      ) : (
        <>
          {idx % 16 !== 0 ? (
            <div className="w-full mx-2">
              <CustomizeDecorBanner />
            </div>
          ) : (
            <LoginBanner />
          )}
        </>
      )}
    </Fragment>
  );
}
