import { memo, useContext, useEffect, useRef, useState } from "react";
import "./styles/form.css";
import "./SideBar.css";
import MainForm from "./MainForm";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  //------------------ref-------------------------------------
  const modalRef = useRef(null);

  const nav = useNavigate();

  const { setAuthPage } = useContext(AuthContext);
  // -------------- STATES ----------------
  const [isModalAppear, setIsModalAppear] = useState(false);

  // ------------ STYLES --------------------------------
  const modalAppearStyle = "translate-x-[0rem]";
  const modalDisappearStyle = "translate-x-[25rem]";

  // ----------------------- USEEFFECTS ---------------------
  useEffect(() => {
    setTimeout(() => {
      setIsModalAppear(true);
    }, 10);

    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //

  //   const disableScroll = () => {
  //     if (isModalAppear) {
  //       disableBodyScroll(modalRef.current); // Pass the scrollable element
  //     }
  //   };
  //   disableScroll()
  // }, [isModalAppear]);

  // ----------------- function ---------------------------
  const modalClose = () => {
    setIsModalAppear(false);
    setAuthPage((prev) => ({
      ...prev,
      login: true,
      otp: false,
    }));
    nav(-1);
  };

  return (
    <div
      ref={modalRef}
      className="fixed right-0 top-1 left-0 bottom-0 overflow-scroll z-[99] scroll-bar-remove scroll-smooth w-[100vw]"
    >
      <div
        className={` md:w-[25rem] w-screen fixed top-0  bottom-0   pb-[2rem] bg-[white] ${
          isModalAppear ? modalAppearStyle : modalDisappearStyle
        } duration-2000 transition-all h-full text-base-content z-[51] `}
      >
        <MainForm modalClose={modalClose} />
      </div>
    </div>
  );
};

export default memo(Sidebar);
