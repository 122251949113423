import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { BiMessageAltDots } from "react-icons/bi";

export default function PageEndBanner() {
  const { user } = useContext(AuthContext);
  const number = "+919930699955";
  const msgContent = `Hi, I'm ${
    user?.name || "new at ebo"
  }, I need help regarding customization of decor.`;
  return (
    <div className="flex items-center md:justify-around w-[100vw] p-6 pb-8 bg-[#f1f6ff]">
      <div>
        <p className="text-[28px] leading-[32px] w-[80%]  font-bold">
          Can't find what you are looking for?
        </p>
        <div
          onClick={() => {
            window.openUrl(`https://wa.me/${number}?text=${msgContent}`);
          }}
        >
          <button className="mt-2  border rounded-[5px] bg-white py-1 px-2 font-bold text-[20px] ">
            Let us know
          </button>
        </div>
      </div>
      <div className="cursor-pointer">
        <BiMessageAltDots size={35} />
      </div>
    </div>
  );
}
