import { FaWhatsapp } from "react-icons/fa";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import GLobalStoreContext from "../../context/GlobalStoreContext";

export default function FloatingWhatsapp({ pageName, productCode }) {
  const text = "Book on Chat";
  const { user } = useContext(AuthContext);
  const { sendMessageToNative } = useContext(GLobalStoreContext);

  const number = "+919930699955";
  let position = {
    bottom: pageName === "home" || pageName === "product" ? "7.5rem" : "3rem",
  };

  const content = {
    home: {
      content: user
        ? `Hi, I'm ${user.name}, I need help regarding product selection.`
        : "Hi, I'm new at ebo, I need help regarging product selection.",
    },
    products: {
      content: user
        ? `Hi, I'm ${user.name}, I need help regarding product selection.`
        : "Hi, I'm new at ebo, I need help regarding product selection.",
    },
    product: {
      content: user
        ? `Hi, I'm ${user.name}, I'm trying to book a product whose product code is ${productCode}.`
        : `Hi, I'm new at ebo, I'm trying to book a product whose product code is ${productCode}.`,
    },
  };

  return (
    <div
      onClick={() => {
        sendMessageToNative({
          action: "",
          trigger: "vibration",
          impactLevel: "light",
        });
      }}
      style={position}
      className=" gap-[6px]  fixed -right-[6.9rem] hover:right-[.5rem] z-[5] px-3 py-2  bg-[#31a73f] text-[white]  shadow-md  rounded-full flex items-center justify-start cursor-pointer duration-500  "
    >
      <FaWhatsapp className="text-[2rem] relative bottom-[1px] text-[white] " />
      <div
        onClick={() => {
          window.openUrl(
            `https://wa.me/${number}?text=${content[pageName].content}`
          );
        }}
        className="text-[1rem] text-[white] hover:text-[white] "
      >
        {text}
      </div>
    </div>
  );
}
