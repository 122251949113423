import React, { memo, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "./authSidebar/Sidebar";

function PrivateRoute() {
  const { user } = useContext(AuthContext);

  return user ? <Outlet /> : <Sidebar />;
}

export default memo(PrivateRoute);
