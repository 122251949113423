import React from "react";

export default function MapEmbed({
  lat,
  lng,
  height = "500px",
  width = "100%",
}) {
  const KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  return (
    <iframe
      width={width}
      height={height}
      loading="lazy"
      title="google map"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed/v1/place?key=${KEY}&q=${lat},${lng}`}
    ></iframe>
  );
}
