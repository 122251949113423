import React, { useContext, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { ImBin2 } from "react-icons/im";
import CompRenderingContext from "../context/CompRenderingContext";

export default function AddonEditTextModal({
  isAddonTextInputModal,
  setIsAddonTextInputModal,
}) {
  // -------------- state --------------------
  const [formData, setFormData] = useState({ text: "" });

  // ---------------------------- destructuring --------------------
  const { addon, text, addTextAddon } = isAddonTextInputModal;
  const { locationData } = useContext(CompRenderingContext);

  const regex = /^[a-zA-Z0-9]+$/;

  const addonPriceDetail = addon.listingPriceHubWise?.find(
    (currHub) => currHub.hub === locationData?.selectedLocation?.hubId
  );
  // --------------------- useEffect --------------------
  useEffect(() => {
    setFormData({
      text: text,
    });
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (regex.test(value) || value.length === 0) {
      setFormData({
        text: e.target.value,
      });
    }
  };
  const handleClose = () => {
    setIsAddonTextInputModal({
      isRender: false,
      text: "",
      setText: null,
      addAddon: null,
      addon: null,
    });
  };
  return (
    <div
      style={{ margin: 0 }}
      className="fixed z-50 top-0 right-0 left-0 bottom-0  flex items-center justify-center"
    >
      <div
        onClick={handleClose}
        className=" fixed top-0 left-0 bottom-0 right-0 bg-[#000000b3] z-[1] "
      ></div>
      <div className="bg-white flex flex-col relative  w-[25rem] rounded-lg z-[2] max-w-[95%] p-2 ">
        <div className="w-full  flex justify-between  border-b border-[#d9d9d9] pb-2 mb-2 ">
          <div className="text-[1rem] text-black font-[600] flex items-center gap-1 leading-5">
            <span className="font-[500]">Add content for</span> '{" "}
            <div className=" inline-block whitespace-nowrap max-w-[8rem] overflow-hidden overflow-ellipsis  ">
              {addon.addonName}
            </div>
            '
          </div>
          <RxCross2 onClick={handleClose} className="text-[1.25rem] " />
        </div>
        <div className="flex gap-2 w-full border items-center rounded-md my-2 overflow-hidden px-2 border-[#d9d9d9]">
          <input
            type="text"
            value={formData.text}
            onChange={handleInputChange}
            className="w-full py-2 focus:outline-none text-[.825rem] "
            placeholder="Type here"
          />
          {formData.text.length > 0 && (
            <ImBin2
              onClick={async () => {
                setFormData({
                  text: "",
                });
              }}
              className="  text-[#b62222] text-[1rem] cursor-pointer  "
            />
          )}
        </div>

        <div className="flex items-end justify-between py-2 text-[#171717] text-[.925rem] pl-1 ">
          <div className="flex flex-col text-[.825rem] text-[black]">
            <div className="flex items-center gap-1">
              <span>Cost Per Piece:</span>
              <span className="font-[500]">
                ₹{addonPriceDetail.sellingPrice}
              </span>
            </div>{" "}
            <div className="flex items-center gap-1">
              <span>
                Total cost ( ₹{addonPriceDetail?.sellingPrice} *{" "}
                {formData.text.length} ):
              </span>
              <span className="font-[500]">
                ₹{formData.text.length * addonPriceDetail?.sellingPrice}
              </span>
            </div>
          </div>

          <button
            onClick={async () => {
              setIsAddonTextInputModal({
                isRender: false,
                text: "",
                setText: null,
                addAddon: null,
                addon: null,
              });
              await addTextAddon(formData);
            }}
            className=" blue-gradient py-1 px-4 rounded-md  "
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
