import React, { useContext, useState, lazy, Suspense } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import "./CalendarPop.css";
import CartContext from "../../context/CartContext";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";
import CompRenderingContext from "../../context/CompRenderingContext";
import GLobalStoreContext from "../../context/GlobalStoreContext";
import { motion } from "framer-motion";
const Slider = lazy(() => import("./subComponents/Slider"));

export default function CalendarPop({
  setIsCalendar,
  validStartingDate,
  validEndingDate,
  minProductAvabilityDays,
  getTheDateOnly,
  handleCatchDate,
  defaultDate,
  unavailableDays,
}) {
  const isBrowser = typeof window !== "undefined";
  const { temp_cart_booking_details, tempCart } = useContext(CartContext);
  const { userStates, setUserStates } = useContext(GLobalStoreContext);
  const { setToastMsg } = useContext(CompRenderingContext);
  const [dateValue, setDateValue] = useState(
    getTheDateOnly
      ? defaultDate
      : moment(userStates.defaultCelebrationDate).format("DD-MM-YYYY")
  );

  const toastPop = (message) => {
    setToastMsg({
      msg: message,
      isRender: true,
    });
  };

  function onChange(value) {
    setDateValue(moment(value).format("DD-MM-YYYY"));
  }

  const handleClick = async () => {
    if (!dateValue) {
      toastPop("Please Select any date");
      return;
    }
    if (getTheDateOnly) {
      handleCatchDate(moment(dateValue, "DD-MM-YYYY"));
      setIsCalendar(false);
      return;
    }

    setUserStates((p) => ({
      ...p,
      defaultCelebrationDate: moment(dateValue, "DD-MM-YYYY").toISOString(),
    }));

    //  LC_TEMP_CART ** HANDLING THE BOOKING DATE MODIFICATION **
    if (tempCart) {
      await temp_cart_booking_details({
        bookingDate: dateValue,
        bookingTime: "",
      });
    }

    setIsCalendar(false);
  };

  return (
    <div
      className={` bg-[#00000098] fixed top-0 right-0 left-0 bottom-0 z-[20] flex items-center flex-col 
   justify-end  `}
    >
      <motion.div
        initial={{ translateY: 500 }}
        animate={{ translateY: 0 }}
        exit={{ translateY: 700 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
        className="calendar-pop flex items-center  flex-col"
      >
        <div
          onClick={() => {
            setIsCalendar(false);
          }}
          className=" calendar-close absolute right-0 -top-10 flex "
        >
          <RxCross2 />
        </div>
        {isBrowser ? (
          <Suspense fallback={<div className="loader w-[2rem]"></div>}>
            <Slider
              toastPop={toastPop}
              setDate={onChange}
              selectedDate={dateValue}
              minProductAvabilityDays={minProductAvabilityDays}
              validStartingDate={validStartingDate}
              validEndingDate={validEndingDate}
              numberOfAvailableDate={30}
              unavailableDays={unavailableDays}
            />
          </Suspense>
        ) : (
          <p>Fetching....</p>
        )}
        <div className="flex border-t  px-3 py-3  justify-between items-center w-full ">
          <div
            onClick={() => {
              toastPop("We take bookings within 30 Days.");
            }}
            className=" text-[#171717] text-[.8rem] flex items-center gap-1  "
          >
            <AiOutlineInfoCircle className=" text-[#2136d4] text-[.9rem] " />
            Additional booking information
          </div>
          <div
            onClick={handleClick}
            className=" bg-[#2136d4] cursor-pointer text-[1rem] text-[white] py-[.6rem] px-[1.2rem] rounded-md  "
          >
            Continue
          </div>
        </div>
      </motion.div>{" "}
    </div>
  );
}

// How to use it :-

/*
  const [isCalendar, setIsCalendar] = useState(false);
  
  {isCalendar && (
    <CalendarPop
    setIsCalendar       --> state setter
    validStartingDate       --> starting date from which the user can select the date
    validEndingDate         --> ending date after which the user can't select the date
    minProductAvabilityDays --> some products need some min days to prepared on so we can pass that min days to tell the user it 
    is not available before x days.
    />
    )}
    
    
    --> example
    
      const [isCalendar, setIsCalendar] = useState(false);
      
      
    
      <AnimatePresence>
         {isCalendar && (
          <CalendarPop
            setIsCalendar={setIsCalendar}
            validStartingDate={moment()}
            validEndingDate={moment().add(30, "days")}
            minProductAvabilityDays={0}
            getTheDateOnly={true}
            handleCatchDate={(date) => {
              // date is a moment object
              setComponentData((p) => ({ ...p, selectedDate: date }));
            }}
          />
        )}
      </AnimatePresence>
       
    

 */
