import React, { createContext, useContext, useState } from "react";
import ProductPreviewContext from "./ProductPreviewContext";
import { get_product_by_query_object } from "../api/productApi";
import CompRenderingContext from "./CompRenderingContext";

const SingleProductContext = createContext();

export const SingleProductProvider = ({ children }) => {
  const [initialProductState, setInitialProductState] = useState({
    product: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
  });
  const { locationData } = useContext(CompRenderingContext);

  const resetProductState = () => {
    setInitialProductState((prev) => ({
      ...prev,
      isError: false,
      isSuccess: false,
      isLoading: false,
      message: "",
    }));
  };
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { previewProducts } = useContext(ProductPreviewContext);
  const setCurrentProduct = (product) => {
    setInitialProductState({ ...initialProductState, product });
  };

  const getSingleProductData = async (productId, projectionString) => {
    setInitialProductState((prev) => ({ ...prev, isLoading: true }));

    try {
      const product = await getProductById(productId, projectionString);
      console.log(product);
      setInitialProductState((prev) => ({
        ...prev,
        product: product,
        isLoading: false,
        isSuccess: true,
      }));
    } catch (error) {
      console.log(error);

      setInitialProductState((prev) => ({
        ...prev,
        isLoading: false,
        isError: true,
      }));
    }
  };

  const getProductById = async (productId, projectionString) => {
    for (const currProduct of previewProducts.previewProductData) {
      if (currProduct._id === productId) {
        return currProduct;
      }
    }

    const res = await get_product_by_query_object({
      BaseUrl: REACT_APP_BACKEND_URL,
      queryObject: { _id: productId },
      skip: 0,
      limit: 1,
      sortingObj: {},
      projectionString,
      hubId: locationData?.selectedLocation?.hubId,
    });

    return res.products[0];
  };

  return (
    <SingleProductContext.Provider
      value={{
        initialProductState,
        resetProductState,
        getSingleProductData,
        setCurrentProduct,
        setInitialProductState,
        getProductById,
      }}
    >
      {children}
    </SingleProductContext.Provider>
  );
};

export default SingleProductContext;
