import { useContext, useState, memo } from "react";
import PhoneInput from "./PhoneInput";
import AuthContext from "../../context/AuthContext";
import Button from "./Button";
import Error from "../../assets/Error";
import TempAuthContext from "../../context/TempAuthContext";
import DOBPicker from "./DOBPicker";
import { FaMale, FaFemale, FaRegCalendarAlt } from "react-icons/fa";
import { SlUserFemale } from "react-icons/sl";

import CompRenderingContext from "../../context/CompRenderingContext";
import { BiSolidUser } from "react-icons/bi";

const RegisterForm = ({modalClose}) => {
  // --------------- ** contexts ** ----------------

  const { number, registerUser, error } = useContext(AuthContext);
  const { removeTempUser } = useContext(TempAuthContext);
  const { setToastMsg } = useContext(CompRenderingContext);

  // --------------- ** states ** ----------------

  const [formData, setFormData] = useState({
    nameTitle: "Mr.",
    gender: "male",
    name: "",
    email: "",
    emailUpdate: true,
    phoneUpdate: true,
    whatsappUpdate: true,
  });

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [isOpenDOBSelector, setIsOpenDOBSelector] = useState(false);

  // --------------- ** destructuring ** ----------------

  const {
    nameTitle,
    name,
    email,
    emailUpdate,
    phoneUpdate,
    whatsappUpdate,
    gender,
  } = formData;

  // ---------------- ** dob regex ** --------------------

  const dobRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-([0-9]{4})$/;

  // --------------- ** functions ** ----------------

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // if (name === "nameTitle") {
    //   let gender = "male";
    //   if (value === "Mr.") gender = "male";
    //   else gender = "female";

    //   setFormData((prev) => ({
    //     ...prev,
    //     gender: gender,
    //   }));
    // }
    setFormData((prev) => ({
      ...prev,
      [name]: value !== "on" ? value : e.target.checked,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!dobRegex.test(dateOfBirth) && dateOfBirth !== "") {
      setToastMsg({
        isRender: true,
        msg: "Invalid Date of Birth",
      });
      return;
    }

    const formDataCopy = {
      phoneNumber: number,
      nameTitle,
      gender,
      name: name === "" ? "User" : name,
      email,
      dateOfBirth,
      phoneUpdate,
      emailUpdate,
      whatsappUpdate,
    };
    await registerUser(formDataCopy, true);
    removeTempUser();
    // setFormData({
    //   nameTitle: "Mr.",
    //   gender: "",
    //   name: "",
    //   email: "",
    //   emailUpdate: true,
    //   phoneUpdate: true,
    //   whatsappUpdate: true,
    // });
  };

  const handleDobChange = (e) => {
    const value = e.target.value;

    if (value.length > 10) return;

    const regex = /^[0-9]+$/;

    if (value.length > dateOfBirth.length) {
      if (!regex.test(value.charAt(value.length - 1))) return;
      if (dateOfBirth.length === 2 || dateOfBirth.length === 5) {
        setDateOfBirth((prev) => prev + "-" + value.charAt(value.length - 1));
      } else {
        setDateOfBirth(value);
      }
    } else {
      setDateOfBirth(value);
    }
  };

  const handleGenderChange = (gender) => {
    let title = "Mr.";
    if (gender === "male") title = "Mr.";
    else if (gender === "female") title = "Ms./Mrs.";
    setFormData((prev) => ({
      ...prev,
      gender: gender,
      title: title,
    }));
  };

  const handleSkipClick = async () => {
    modalClose();
  };

  return (
    <div className="scroll-bar-remove">
      <div className="flex justify-between items-center pt-1 sticky top-0 bg-white">
        <p
          style={{
            fontFamily: "'Josefin Sans', sans-serif",
          }}
          className="font-bold text-[44px] text-[#2136D4]"
        >
          ebo
        </p>
        <p
          className="font-semibold border rounded-xl px-4 bg-[#F0F0F0] cursor-pointer"
          onClick={handleSkipClick}
        >
          SKIP
        </p>
      </div>
      <div className="mt-1">
        <p className="text-xl font-semibold">Register</p>
        <hr className="mt-1 border-t-2 border-solid border-[#2136D4] w-[60px] " />
      </div>
      <DOBPicker
        isOpenDOBSelector={isOpenDOBSelector}
        setIsOpenDOBSelector={setIsOpenDOBSelector}
        setDateOfBirth={setDateOfBirth}
        dateOfBirth={dateOfBirth}
      />
      <form onSubmit={onSubmit}>
        <div className="flex flex-col pb-[10px] ">
          <div className="mobile-container">
            <PhoneInput number={number} disabled={true} />
          </div>
          <div className="flex gap-4 font-medium">
            <div
              className={`flex gap-1 border py-2 px-4 rounded-lg ${
                gender === "male" && "bg-[#F0F5FE]"
              }`}
              onClick={() => handleGenderChange("male")}
            >
              <BiSolidUser
                className={`text-2xl ${gender === "male" && "text-[#2136D4]"}`}
              />
              <p>Male</p>
            </div>
            <div
              className={`flex gap-1 border py-2 px-4 rounded-lg ${
                gender === "female" && "bg-[#F0F5FE]"
              }`}
              onClick={() => handleGenderChange("female")}
            >
              <SlUserFemale
                className={`text-2xl ${
                  gender === "female" && "text-[#2136D4]"
                }`}
              />
              <p>Female</p>
            </div>
          </div>
          <input
            type="text"
            name="name"
            value={name}
            onChange={onChange}
            autoComplete="on"
            className="mt-[1.5rem] select-options my-[.5rem]  input-box-name border py-3 px-4 rounded-[10px] "
            placeholder="Enter your name"
            id="email"
          />

          <p className="mt-[1rem] text-[#B7B1B1] text-[12px]">Date of birth</p>
          <div className=" flex justify-between  mt-1 mb-[1rem] border py-3 px-4 rounded-[10px]">
            <input
              type="text"
              value={dateOfBirth}
              onChange={handleDobChange}
              className="  outline-none "
              placeholder="DD / MM / YYYY"
            />
            <FaRegCalendarAlt
              onClick={() => {
                setIsOpenDOBSelector(true);
              }}
              className="text-[1.25rem]  cursor-pointer right-[.5rem] text-[#979797]"
            />
          </div>
          <input
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            autoComplete="on"
            className="select-options my-[.5rem]   input-box-name border py-3 px-4 rounded-[10px] "
            placeholder="Email (OPTIONAL)"
            id="email"
          />
          <Error error={error} />
          <Button type="submit" text={"Continue"} />
        </div>
      </form>
    </div>
  );
};

export default memo(RegisterForm);
