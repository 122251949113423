import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../assets/Header";

export default function MyBookingsPrivateRoute() {
  const { user } = useContext(AuthContext);

  return user ? <Outlet /> : <Component />;
}

function Component() {
  const navigate = useNavigate();
  return (
    <div className="">
      <Header title="My Bookings" />
      <div className="flex flex-col justify-center items-center h-[100vh]  g">
        {/* <p className="text-lg font-medium">No Bookings found</p> */}
        <p className="font-meduim ">Login to view you bookings </p>

        <button
          onClick={() => {
            navigate("/login");
          }}
          className="blue-gradient rounded-lg px-4 py-2 w-[80%] mt-2"
        >
          Login
        </button>
      </div>
    </div>
  );
}
